const loginUser = (username, password) => {
  return fetch("http://200.72.35.93:8080/api/login", { 
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error en la solicitud');
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
};

export default loginUser;
